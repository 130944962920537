import { ReactNode } from "@tanstack/react-router";
import { Toaster } from "@/components/ui/toaster";
import { UserPreferencesProvider } from "@/components/contexts/UserPreferencesContext";
import { Footer } from "./Footer";
import { Header } from "./Header";

interface LayoutProps {
  children: ReactNode;
}

export function Layout({ children }: LayoutProps) {
  return (
    <UserPreferencesProvider>
      <div className="min-h-screen flex flex-col bg-background">
        <Header />
        {/* Main content should grow and push the footer down if needed */}
        <main className="container mx-auto flex-1 px-4 py-8">
          {children}
          <Toaster />
        </main>
        {/* Footer sticks to the bottom */}
        <Footer />
      </div>
    </UserPreferencesProvider>
  );
}
