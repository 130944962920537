import { useState, useEffect } from "react";
import { ClerkProvider, SignIn, useAuth, useSession } from "@clerk/clerk-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "@tanstack/react-router";
import { router } from "./router";
import { createClient, SupabaseClient } from "@supabase/supabase-js";

const queryClient = new QueryClient();
const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing key(s)");
}
let supabaseClient: SupabaseClient | null = null;

// Create a custom supabase client that injects the Clerk Supabase token into the request headers
export function createClerkSupabaseClient() {
  if (supabaseClient) {
    return supabaseClient;
  }
  // The `useSession()` hook will be used to get the Clerk `session` object
  const { session } = useSession();
  const VITE_PUBLIC_SUPABASE_URL = import.meta.env.VITE_PUBLIC_SUPABASE_URL;
  const VITE_PUBLIC_SUPABASE_KEY = import.meta.env.VITE_PUBLIC_SUPABASE_KEY;

  if (!VITE_PUBLIC_SUPABASE_URL || !VITE_PUBLIC_SUPABASE_KEY) {
    throw new Error("Missing Supabase key(s)");
  }
  supabaseClient = createClient(
    VITE_PUBLIC_SUPABASE_URL,
    VITE_PUBLIC_SUPABASE_KEY,
    {
      global: {
        // Get the custom Supabase token from Clerk
        fetch: async (url, options = {}) => {
          // The Clerk `session` object has the getToken() method
          const clerkToken = await session?.getToken({
            // Pass the name of the JWT template you created in the Clerk Dashboard
            // For this tutorial, you named it 'supabase'
            template: "supabase",
          });

          // Insert the Clerk Supabase token into the headers
          const headers = new Headers(options?.headers);
          headers.set("Authorization", `Bearer ${clerkToken}`);

          // Call the default fetch
          return fetch(url, {
            ...options,
            headers,
          });
        },
      },
    }
  );
  return supabaseClient;
}

function InnerApp() {
  const { isLoaded, isSignedIn } = useAuth();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      setIsReady(true);
    }
  }, [isLoaded]);

  if (!isReady) {
    const theme = localStorage.getItem("theme") || "light";
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    }
    return (
      <>
        <div className="flex items-center justify-center h-screen">
          <span className="loading loading-infinity loading-lg"></span>
        </div>
      </>
    );
  }
  if (!isSignedIn) {
    return (
      <>
        <div className="flex justify-center items-center h-screen">
          <SignIn />
        </div>
      </>
    );
  }

  return <RouterProvider router={router} />;
}

function App() {
  return (
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <QueryClientProvider client={queryClient}>
        <InnerApp />
      </QueryClientProvider>
    </ClerkProvider>
  );
}

export default App;
