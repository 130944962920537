import { useState, useEffect } from "react";
import { createClerkSupabaseClient } from "@/App";
import { useUser } from "@clerk/clerk-react";
import { TimeTrackingForm } from "./TimeTrackingForm";
import { SummaryCard } from "./SummaryCard";
import { RecentEntriesTable } from "./RecentEntriesTable";
import { Loader2 } from "lucide-react";
import {
  Client,
  Project,
  TimeEntry,
  TimeEntryStatus,
  Tag,
  UserPreferences,
  TimeEntryWithTags,
  TimeEntryTag,
} from "@/models";
import { useToast } from "@/hooks/use-toast";
import { OnboardingDialog } from "./OnboardingDialog";
import { useUserPreferences } from "../contexts/UserPreferencesContext";

type SummaryPeriod = "today" | "week" | "month" | "year" | "custom";

interface SummaryData {
  hours: string;
  clients: number;
  projects: number;
  earnings: number;
}

export default function Dashboard() {
  const { user } = useUser();
  const [projects, setProjects] = useState<Project[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [timeEntries, setTimeEntries] = useState<TimeEntryWithTags[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const { userPreferences, setUserPreferences } = useUserPreferences();

  const [loading, setLoading] = useState(true);
  const [currentTracking, setCurrentTracking] =
    useState<TimeEntryWithTags | null>(null);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [summaryUpdateTrigger, setSummaryUpdateTrigger] = useState(0);
  const { toast } = useToast();

  const supabase = createClerkSupabaseClient();

  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);

  async function fetchData() {
    setLoading(true);
    const [
      projectsData,
      clientsData,
      timeEntriesData,
      tagsData,
      preferencesData,
    ] = await Promise.all([
      supabase.from("projects").select("*").eq("user_id", user?.id),
      supabase.from("clients").select("*").eq("user_id", user?.id),
      supabase
        .from("time_entries")
        .select(
          `
          *,
          tags:time_entry_tags(tag:tags(*))
        `
        )
        .eq("user_id", user?.id)
        .order("start_time", { ascending: false }),
      supabase.from("tags").select("*").eq("user_id", user?.id),
      supabase
        .from("user_preferences")
        .select("*")
        .eq("user_id", user?.id)
        .single(),
    ]);

    if (projectsData.data) setProjects(projectsData.data as Project[]);
    if (clientsData.data) setClients(clientsData.data as Client[]);
    if (timeEntriesData.data) {
      const formattedTimeEntries = timeEntriesData.data.map((entry: any) => ({
        ...entry,
        tags: entry.tags.map((t: any) => t.tag),
      }));
      setTimeEntries(formattedTimeEntries as TimeEntryWithTags[]);
    }
    if (tagsData.data) setTags(tagsData.data as Tag[]);

    if (preferencesData.data) {
      setUserPreferences(preferencesData.data as UserPreferences);
    } else {
      setShowOnboarding(true);
    }

    setLoading(false);
  }

  async function startTracking(timeEntry: TimeEntryWithTags) {
    const project = projects.find((p) => p.id === timeEntry.project_id);
    if (!project) return;

    setCurrentTracking(timeEntry);
    setTimeEntries([timeEntry, ...timeEntries]);
  }

  async function stopTracking() {
    if (currentTracking) {
      const endTime = new Date().toISOString();

      const updatedTimeEntry: Partial<TimeEntry> = {
        end_time: endTime,
        status: TimeEntryStatus.Completed,
      };

      const { data, error } = await supabase
        .from("time_entries")
        .update(updatedTimeEntry)
        .eq("id", currentTracking.id)
        .select(
          `
          *,
          tags:time_entry_tags(tag:tags(*))
        `
        )
        .single();

      if (error) {
        console.error("Error updating time entry:", error);
        return;
      }

      if (data) {
        const formattedTimeEntry = {
          ...data,
          tags: data.tags.map((t: any) => t.tag),
        };
        setTimeEntries(
          timeEntries.map((te) => (te.id === data.id ? formattedTimeEntry : te))
        );
        setCurrentTracking(null);
        setSummaryUpdateTrigger((prev) => prev + 1);
      } else {
        console.error("No data returned from update query");
      }
    }
  }
  const handleEditEntry = async (
    timeEntryId: string,
    updatedEntry: Partial<TimeEntry>,
    updatedTagNames: string[]
  ) => {
    if (!user || !user.id) return;
    try {
      // Update the time entry
      const { error: updateError } = await supabase
        .from("time_entries")
        .update(updatedEntry)
        .eq("id", timeEntryId)
        .select()
        .single();

      if (updateError) throw updateError;

      // Fetch or create tags based on the updated tag names
      const tagPromises = updatedTagNames.map(async (tagName) => {
        const { data, error } = await supabase
          .from("tags")
          .select("*")
          .eq("name", tagName)
          .eq("user_id", user?.id)
          .single();

        if (error) {
          if (error.code === "PGRST116") {
            // Tag doesn't exist, create it
            const { data: newTag, error: createError } = await supabase
              .from("tags")
              .insert({ name: tagName, user_id: user?.id })
              .select()
              .single();

            if (createError) throw createError;
            return newTag;
          }
          throw error;
        }

        return data;
      });

      const tags = await Promise.all(tagPromises);

      // Delete existing time entry tags
      await supabase
        .from("time_entry_tags")
        .delete()
        .eq("time_entry_id", timeEntryId);

      // Insert new time entry tags
      const timeEntryTags: TimeEntryTag[] = tags.map((tag) => ({
        time_entry_id: timeEntryId,
        tag_id: tag.id,
        user_id: user?.id,
      }));

      const { error: insertError } = await supabase
        .from("time_entry_tags")
        .insert(timeEntryTags);

      if (insertError) throw insertError;

      // Fetch the updated entry with tags
      const { data: finalEntry, error: fetchError } = await supabase
        .from("time_entries")
        .select(
          `
          *,
          tags:time_entry_tags(tag:tags(*))
        `
        )
        .eq("id", timeEntryId)
        .single();

      if (fetchError) throw fetchError;

      if (finalEntry) {
        const formattedTimeEntry = {
          ...finalEntry,
          tags: finalEntry.tags.map((t: any) => t.tag),
        };
        setTimeEntries((entries) =>
          entries.map((entry) =>
            entry.id === timeEntryId ? formattedTimeEntry : entry
          )
        );
        toast({
          title: "Time entry updated",
          description: "Your time entry has been successfully updated.",
        });
      }
    } catch (error) {
      console.error("Error updating time entry:", error);
      toast({
        title: "Error updating time entry",
        description:
          "There was a problem updating your time entry. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleAddEntry = async (
    newEntry: Omit<TimeEntry, "id">,
    newTags: string[]
  ) => {
    if (!user || !user.id) return;
    try {
      const newEntryWithUserId = { ...newEntry, user_id: user?.id };

      // Insert the new time entry
      const { data: insertedEntry, error: insertError } = await supabase
        .from("time_entries")
        .insert(newEntryWithUserId)
        .select()
        .single();

      if (insertError) throw insertError;

      if (insertedEntry) {
        // Fetch or create tags based on the new tag names
        const tagPromises = newTags.map(async (tagName) => {
          const { data, error } = await supabase
            .from("tags")
            .select("*")
            .eq("name", tagName)
            .eq("user_id", user?.id)
            .single();

          if (error) {
            if (error.code === "PGRST116") {
              // Tag doesn't exist, create it
              const { data: newTag, error: createError } = await supabase
                .from("tags")
                .insert({ name: tagName, user_id: user?.id })
                .select()
                .single();

              if (createError) throw createError;
              return newTag;
            }
            throw error;
          }

          return data;
        });

        const tags = await Promise.all(tagPromises);

        // Create time entry tags
        const timeEntryTags: TimeEntryTag[] = tags.map((tag) => ({
          time_entry_id: insertedEntry.id,
          tag_id: tag.id ?? "",
          user_id: user?.id,
        }));

        const { error: linkError } = await supabase
          .from("time_entry_tags")
          .insert(timeEntryTags);

        if (linkError) throw linkError;

        // Fetch the newly inserted entry with tags
        const { data: finalEntry, error: fetchError } = await supabase
          .from("time_entries")
          .select(
            `
            *,
            tags:time_entry_tags(tag:tags(*))
          `
          )
          .eq("id", insertedEntry.id)
          .single();

        if (fetchError) throw fetchError;

        if (finalEntry) {
          const formattedTimeEntry = {
            ...finalEntry,
            tags: finalEntry.tags.map((t: any) => t.tag),
          };
          setTimeEntries((entries) => [formattedTimeEntry, ...entries]);
          setSummaryUpdateTrigger((prev) => prev + 1);
          toast({
            title: "Time entry added",
            description: "Your time entry has been successfully added.",
          });
        }
      }
    } catch (error) {
      console.error("Error adding time entry:", error);
      toast({
        title: "Error adding time entry",
        description:
          "There was a problem adding your time entry. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleDeleteEntry = async (id: string) => {
    try {
      // Delete time entry tags first
      const { error: tagDeleteError } = await supabase
        .from("time_entry_tags")
        .delete()
        .eq("time_entry_id", id);

      if (tagDeleteError) throw tagDeleteError;

      // Then delete the time entry
      const { error: entryDeleteError } = await supabase
        .from("time_entries")
        .delete()
        .eq("id", id);

      if (entryDeleteError) throw entryDeleteError;

      setTimeEntries((entries) => entries.filter((entry) => entry.id !== id));
      setSummaryUpdateTrigger((prev) => prev + 1);
      toast({
        title: "Time entry deleted",
        description: "Your time entry has been successfully deleted.",
      });
    } catch (error) {
      console.error("Error deleting time entry:", error);
      toast({
        title: "Error deleting time entry",
        description:
          "There was a problem deleting your time entry. Please try again.",
        variant: "destructive",
      });
    }
  };

  const getSummaryData = async (
    period: SummaryPeriod
  ): Promise<SummaryData> => {
    let startDate: Date;
    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);

    switch (period) {
      case "today":
        startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        break;
      case "week":
        startDate = new Date();
        startDate.setDate(startDate.getDate() - 7);
        break;
      case "month":
        startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        break;
      case "year":
        startDate = new Date();
        startDate.setFullYear(startDate.getFullYear() - 1);
        break;
      default:
        startDate = new Date(0);
    }

    const { data, error } = await supabase
      .from("time_entries")
      .select("*, projects(client_id)")
      .gte("start_time", startDate.toISOString())
      .lte("start_time", endDate.toISOString())
      .eq("user_id", user?.id);

    if (error) {
      console.error("Error fetching summary data:", error);
      return { hours: "0:00", clients: 0, projects: 0, earnings: 0 };
    }

    const entries = data as (TimeEntry & { projects: { client_id: string } })[];

    const totalSeconds = entries.reduce((sum, entry) => {
      const endTime = entry.end_time ? new Date(entry.end_time) : new Date();
      const duration =
        (endTime.getTime() - new Date(entry.start_time).getTime()) / 1000;
      return sum + duration;
    }, 0);

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const earnings = entries.reduce((sum, entry) => {
      const endTime = entry.end_time ? new Date(entry.end_time) : new Date();
      const duration =
        (endTime.getTime() - new Date(entry.start_time).getTime()) / 3600000; // hours
      return sum + duration * (entry.hourly_rate || 0);
    }, 0);

    const uniqueClients = new Set(
      entries.map((entry) => entry.projects.client_id)
    ).size;
    const uniqueProjects = new Set(entries.map((entry) => entry.project_id))
      .size;

    return {
      hours: `${hours}:${minutes.toString().padStart(2, "0")}`,
      clients: uniqueClients,
      projects: uniqueProjects,
      earnings: Math.round(earnings * 100) / 100,
    };
  };

  const handleOnboardingComplete = async (
    preferences: UserPreferences | null
  ) => {
    if (!preferences) {
      setShowOnboarding(false);
      return;
    }
    try {
      const newPreferences = {
        ...preferences,
        user_id: user?.id,
      };

      const { data: insertedData, error } = await supabase
        .from("user_preferences")
        .insert(newPreferences)
        .select()
        .single();

      if (error) throw error;

      setUserPreferences(insertedData);
      setShowOnboarding(false);

      toast({
        title: insertedData.name
          ? `Welcome to cronloom, ${insertedData.name}!`
          : "Welcome to cronloom!",
        description: "Your preferences have been saved successfully.",
      });
    } catch (error) {
      console.error("Error saving user preferences:", error);
      toast({
        title: "Error saving preferences",
        description:
          "There was a problem saving your preferences. Please try again.",
        variant: "destructive",
      });
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
      <h1 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 mt-4 sm:mt-6">
        {userPreferences?.name
          ? `Hi there, ${userPreferences.name} 👋!`
          : "Hi there!"}
      </h1>

      <div className="grid gap-4 sm:gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
        <div className="col-span-1 md:col-span-2 lg:col-span-1">
          <TimeTrackingForm
            clients={clients}
            projects={projects}
            userPreferences={userPreferences}
            onStartTracking={startTracking}
            onStopTracking={stopTracking}
            currentTracking={currentTracking}
          />
        </div>
        <div className="col-span-1 md:col-span-2 lg:col-span-1">
          <SummaryCard
            getSummaryData={getSummaryData}
            updateTrigger={summaryUpdateTrigger}
          />
        </div>
        <div className="col-span-1 md:col-span-2 lg:col-span-2">
          <RecentEntriesTable
            entries={timeEntries}
            projects={projects}
            clients={clients}
            tags={tags}
            onDelete={handleDeleteEntry}
            onEdit={handleEditEntry}
            onAdd={handleAddEntry}
          />
        </div>
      </div>

      <OnboardingDialog
        isOpen={showOnboarding}
        onClose={handleOnboardingComplete}
      />
    </div>
  );
}
