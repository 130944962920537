import { createFileRoute } from "@tanstack/react-router";
import { useState, useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { createClerkSupabaseClient } from "@/App";

export interface UserPreferences {
  user_id: string;
  default_hourly_rate: number;
  currency: string;
  name: string;
  created_at: string;
  language: string;
}

const formSchema = z.object({
  default_hourly_rate: z
    .number()
    .min(0, "Hourly rate must be a positive number"),
  currency: z.string().min(3, "Currency must be a valid 3-letter code"),
  name: z.string().min(2, "Name must be at least 2 characters long"),
  language: z.string().min(2, "Language must be a valid 2-letter code"),
});

function SettingsPage() {
  const { user } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [, setUserPreferences] = useState<UserPreferences | null>(null);
  const supabase = createClerkSupabaseClient();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      default_hourly_rate: 0,
      currency: "USD",
      name: user?.fullName || "",
      language: "en",
    },
  });

  useEffect(() => {
    async function fetchUserPreferences() {
      if (!user?.id) return;

      try {
        const { data, error } = await supabase
          .from("user_preferences")
          .select("*")
          .eq("user_id", user.id)
          .single();

        if (error) throw error;

        if (data) {
          setUserPreferences(data);
          form.reset({
            default_hourly_rate: data.default_hourly_rate,
            currency: data.currency,
            name: data.name,
            language: data.language,
          });
        }
      } catch (error) {
        console.error("Error fetching user preferences:", error);
        alert("Failed to load user preferences. Please try again.");
      } finally {
        setIsFetching(false);
      }
    }

    fetchUserPreferences();
  }, [user?.id, form]);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);
    try {
      const { error } = await supabase
        .from("user_preferences")
        .upsert({ ...values, user_id: user?.id }, { onConflict: "user_id" });

      if (error) throw error;

      alert("Your preferences have been saved successfully.");
    } catch (error) {
      console.error("Error updating user preferences:", error);
      alert("Failed to update preferences. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }

  if (isFetching) {
    return (
      <div className="container mx-auto py-10 text-center">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-10 px-4">
      <h1 className="text-3xl font-bold mb-6">User Settings</h1>
      <div className="card bg-base-100 shadow-xl">
        <div className="card-body">
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <div className="form-control">
              <label className="label">
                <span className="label-text">Name</span>
              </label>
              <input
                type="text"
                placeholder="Your name"
                className="input input-bordered w-full"
                {...form.register("name")}
              />
              <label className="label">
                <span className="label-text-alt">
                  This is the name that will be displayed on your profile.
                </span>
              </label>
              {form.formState.errors.name && (
                <span className="text-error text-sm">
                  {form.formState.errors.name.message}
                </span>
              )}
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">Default Hourly Rate</span>
              </label>
              <input
                type="number"
                placeholder="0"
                className="input input-bordered w-full"
                {...form.register("default_hourly_rate", {
                  valueAsNumber: true,
                })}
              />
              <label className="label">
                <span className="label-text-alt">
                  Your default hourly rate for new projects.
                </span>
              </label>
              {form.formState.errors.default_hourly_rate && (
                <span className="text-error text-sm">
                  {form.formState.errors.default_hourly_rate.message}
                </span>
              )}
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">Preferred Currency</span>
              </label>
              <select
                className="select select-bordered w-full"
                {...form.register("currency")}
              >
                <option value="USD">USD - US Dollar</option>
                <option value="EUR">EUR - Euro</option>
              </select>
              <label className="label">
                <span className="label-text-alt">
                  The currency used for displaying rates and earnings.
                </span>
              </label>
              {form.formState.errors.currency && (
                <span className="text-error text-sm">
                  {form.formState.errors.currency.message}
                </span>
              )}
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">Preferred Language</span>
              </label>
              <select
                className="select select-bordered w-full"
                {...form.register("language")}
              >
                <option value="en">English</option>
                <option value="de">Deutsch</option>
              </select>
              <label className="label">
                <span className="label-text-alt">
                  The language used for the user interface.
                </span>
              </label>
              {form.formState.errors.language && (
                <span className="text-error text-sm">
                  {form.formState.errors.language.message}
                </span>
              )}
            </div>

            <button
              type="submit"
              className={`btn btn-primary w-full ${isLoading ? "loading" : ""}`}
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save Changes"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export const Route = createFileRoute("/settings")({
  component: SettingsPage,
});
