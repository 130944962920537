import { createContext, useContext, useState, ReactNode } from "react";
import { UserPreferences } from "@/models";

interface UserPreferencesContextProps {
  userPreferences: UserPreferences | null;
  setUserPreferences: (preferences: UserPreferences | null) => void;
}

const UserPreferencesContext = createContext<
  UserPreferencesContextProps | undefined
>(undefined);

export const UserPreferencesProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [userPreferences, setUserPreferences] =
    useState<UserPreferences | null>(null);

  return (
    <UserPreferencesContext.Provider
      value={{ userPreferences, setUserPreferences }}
    >
      {children}
    </UserPreferencesContext.Provider>
  );
};

export const useUserPreferences = () => {
  const context = useContext(UserPreferencesContext);
  if (context === undefined) {
    throw new Error(
      "useUserPreferences must be used within a UserPreferencesProvider"
    );
  }
  return context;
};
