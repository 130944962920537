import { routeTree } from "@/routeTree.gen";
import { createBrowserHistory, createRouter } from "@tanstack/react-router";

const browserHistory = createBrowserHistory();

export const router = createRouter({
  routeTree,
  history: browserHistory,
  // Add this defaultPreload option
  defaultPreload: "intent",
  // Optionally, you can add this for better SEO handling
  defaultPreloadStaleTime: 0,
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}
