"use client";

import { useState, useEffect } from "react";
import { Bell, Eye, EyeOff } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ScrollArea } from "@/components/ui/scroll-area";
import { createClerkSupabaseClient } from "@/App";
import { useUser } from "@clerk/clerk-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useUserPreferences } from "../contexts/UserPreferencesContext";
import ReactMarkdown from "react-markdown";
import { ReleaseNotes } from "@/models";
import remarkGfm from "remark-gfm";

interface ReleaseNote {
  id: string;
  header: string;
  content: string;
  seen: boolean;
  created_at: string;
  published: boolean;
}

export function NotificationBell() {
  const [notes, setNotes] = useState<ReleaseNote[]>([]);
  const [selectedNote, setSelectedNote] = useState<ReleaseNote | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { userPreferences, setUserPreferences } = useUserPreferences();

  const { user } = useUser();
  const supabase = createClerkSupabaseClient();

  const handleInserts = (payload: any) => {
    const newEntry = payload.new as ReleaseNotes;
    if (newEntry.published === false) return;
    setNotes((prevNotes) => [
      {
        id: newEntry.id,
        header: newEntry.header,
        content: newEntry.content,
        seen: false,
        created_at: newEntry.created_at,
        published: newEntry.published,
      },
      ...prevNotes,
    ]);
  };

  supabase
    .channel("release_notes")
    .on(
      "postgres_changes",
      { event: "INSERT", schema: "public", table: "release_notes" },
      handleInserts as any
    )
    .subscribe();

  const fetchReleaseNotes = async () => {
    if (!user || !userPreferences) return;

    let { data: releaseNotes, error: notesError } = await supabase
      .from("release_notes")
      .select("id, header, content, created_at, published");

    if (notesError || !releaseNotes) {
      console.error("Error fetching release notes:", notesError);
      return;
    }

    releaseNotes = releaseNotes.filter((note) => note.published === true);
    if (releaseNotes.length === 0) return;

    const { data: seenNotes, error: seenError } = await supabase
      .from("release_notes_user")
      .select("release_note_id")
      .eq("user_id", user.id);

    if (seenError) {
      console.error("Error fetching seen release notes:", seenError);
      return;
    }

    const seenNoteIds = new Set(
      seenNotes?.map((note) => note.release_note_id) || []
    );

    // check userPReferences for when the user was created -> mark all release notes from before that as seen
    if (userPreferences && releaseNotes) {
      releaseNotes.forEach((note) => {
        if (
          new Date(note.created_at) < new Date(userPreferences.created_at) &&
          !seenNoteIds.has(note.id)
        ) {
          markAsSeen(note.id);
          seenNoteIds.add(note.id);
        }
      });
    }

    const formattedNotes =
      releaseNotes
        ?.map((note) => ({
          ...note,
          seen: seenNoteIds.has(note.id),
        }))
        .sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        ) || [];

    setNotes(formattedNotes);
  };

  const markAsSeen = async (releaseNoteId: string) => {
    if (!user) return;
    const { error } = await supabase.from("release_notes_user").insert({
      release_note_id: releaseNoteId,
      user_id: user.id,
    });

    if (error) {
      console.error("Error marking release note as seen:", error);
    } else {
      setNotes((prevNotes) =>
        prevNotes.map((note) =>
          note.id === releaseNoteId ? { ...note, seen: true } : note
        )
      );
    }
  };

  const fetchUserPreferences = async () => {
    if (!user) return;
    const { data, error } = await supabase
      .from("user_preferences")
      .select("*")
      .eq("user_id", user.id);

    if (error) {
      console.error("Error fetching user preferences:", error);
      return;
    }
    setUserPreferences(data[0]);
  };

  useEffect(() => {
    fetchUserPreferences();
  }, [user]);

  useEffect(() => {
    fetchReleaseNotes();
  }, [userPreferences]);

  const handleNoteClick = (note: ReleaseNote) => {
    setSelectedNote(note);
    setIsDialogOpen(true);
    if (!note.seen) {
      markAsSeen(note.id);
    }
  };

  const unseenCount = notes.filter((note) => !note.seen).length;

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon" className="relative">
            <Bell className="h-5 w-5" />
            {unseenCount > 0 && (
              <span className="absolute top-0 right-0 h-4 w-4 rounded-full bg-red-500 text-xs text-white flex items-center justify-center">
                {unseenCount}
              </span>
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-80">
          <ScrollArea
            className="max-h-[300px]"
            style={{
              height: `${notes.length > 0 ? Math.min(notes.length * 35, 300) : 50}px`,
            }}
          >
            {notes.length === 0 ? (
              <div className="p-4 text-center text-sm text-gray-500">
                No notifications
              </div>
            ) : (
              notes.map((note) => (
                <DropdownMenuItem
                  key={note.id}
                  onSelect={() => handleNoteClick(note)}
                >
                  <div className="flex items-center w-full">
                    {note.seen ? (
                      <Eye className="h-4 w-4 mr-2 text-gray-400" />
                    ) : (
                      <EyeOff className="h-4 w-4 mr-2 text-blue-500" />
                    )}
                    <span
                      className={`flex-grow ${note.seen ? "text-gray-500" : "font-semibold"}`}
                    >
                      {note.header}
                    </span>
                  </div>
                </DropdownMenuItem>
              ))
            )}
          </ScrollArea>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{selectedNote?.header}</DialogTitle>
          </DialogHeader>
          <div className="mt-2">
            <ReactMarkdown
              className={"whitespace-pre-wrap"}
              remarkPlugins={[remarkGfm]}
              components={{
                a: (props) => {
                  return (
                    // <a href={props.href}>{props.children}</a>
                    // <a href={props.href}>{props.children}</a>
                    <a
                      className="font-bold"
                      href={props.href}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {props.children}
                    </a>
                  );
                },
              }}
            >
              {selectedNote?.content ?? ""}
            </ReactMarkdown>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
