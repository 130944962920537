import { createFileRoute } from "@tanstack/react-router";
import { useState, useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import * as XLSX from "xlsx";
import { createClerkSupabaseClient } from "@/App";
import { Client, Project, TimeEntry, TimeEntryStatus, Tag } from "@/models";

const formSchema = z.object({
  clients: z.array(z.string()),
  projects: z.array(z.string()),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  includeTags: z.boolean(),
});

function ReportsPage() {
  const { user } = useUser();
  const [isGenerating, setIsGenerating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [clients, setClients] = useState<Client[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [timeEntries, setTimeEntries] = useState<TimeEntry[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);

  const supabase = createClerkSupabaseClient();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      clients: [],
      projects: [],
      startDate: "",
      endDate: "",
      includeTags: false,
    },
  });

  useEffect(() => {
    async function fetchData() {
      if (!user) return;

      setIsLoading(true);
      try {
        const [
          clientsResponse,
          projectsResponse,
          timeEntriesResponse,
          tagsResponse,
        ] = await Promise.all([
          supabase.from("clients").select("*").eq("user_id", user.id),
          supabase.from("projects").select("*").eq("user_id", user.id),
          supabase.from("time_entries").select("*").eq("user_id", user.id),
          supabase.from("tags").select("*").eq("user_id", user.id),
        ]);

        if (clientsResponse.error) throw clientsResponse.error;
        if (projectsResponse.error) throw projectsResponse.error;
        if (timeEntriesResponse.error) throw timeEntriesResponse.error;
        if (tagsResponse.error) throw tagsResponse.error;

        setClients(clientsResponse.data);
        setProjects(projectsResponse.data);
        setTimeEntries(timeEntriesResponse.data);
        setTags(tagsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        alert("Failed to load data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [user]);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setIsGenerating(true);
    if (values.endDate) {
      const endDate = new Date(values.endDate);
      endDate.setDate(endDate.getDate() + 1);
      values.endDate = endDate.toISOString().split("T")[0];
    }
    try {
      const filteredEntries = timeEntries.filter((entry) => {
        const projectMatch = values.projects.length
          ? values.projects.includes(entry.project_id)
          : true;
        const clientMatch = values.clients.length
          ? values.clients.includes(
              projects.find((p) => p.id === entry.project_id)?.client_id || ""
            )
          : true;
        const dateMatch =
          values.startDate && values.endDate
            ? new Date(entry.start_time) >= new Date(values.startDate) &&
              new Date(entry.start_time) <= new Date(values.endDate)
            : true;
        const statusMatch = entry.status === TimeEntryStatus.Completed;
        return projectMatch && clientMatch && dateMatch && statusMatch;
      });

      const reportData = await Promise.all(
        filteredEntries.map(async (entry) => {
          const project = projects.find((p) => p.id === entry.project_id);
          const client = clients.find((c) => c.id === project?.client_id);
          const row: any = {
            Date: new Date(entry.start_time).toLocaleDateString(),
            "Start Time": new Date(entry.start_time).toLocaleTimeString(),
            "End Time": entry.end_time
              ? new Date(entry.end_time).toLocaleTimeString()
              : "In Progress",
            Duration: entry.end_time
              ? (
                  (new Date(entry.end_time).getTime() -
                    new Date(entry.start_time).getTime()) /
                  3600000
                ).toFixed(2)
              : "N/A",
            Project: project?.name || "Unknown Project",
            Client: client?.name || "Unknown Client",
            Description: entry.description || "",
            "Hourly Rate": entry.hourly_rate || "Default Rate",
            Status: entry.status,
          };

          if (values.includeTags) {
            const { data: entryTags, error } = await supabase
              .from("time_entry_tags")
              .select("tag_id")
              .eq("time_entry_id", entry.id);

            if (error) throw error;

            const tagNames = entryTags
              .map((et) => tags.find((t) => t.id === et.tag_id)?.name)
              .filter(Boolean);

            row.Tags = tagNames.join(", ");
          }

          return row;
        })
      );

      const ws = XLSX.utils.json_to_sheet(reportData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Time Entries Report");
      XLSX.writeFile(wb, "time_entries_report.xlsx");

      alert("Your XLSX report has been generated and downloaded.");
    } catch (error) {
      console.error("Error generating report:", error);
      alert("There was an error generating the report. Please try again.");
    } finally {
      setIsGenerating(false);
    }
  };

  const selectedClients = form.watch("clients");

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <div className="card bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title">Generate Reports</h2>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <div className="form-control">
              <label className="label">
                <span className="label-text">Clients</span>
              </label>
              <div className="dropdown">
                <label
                  tabIndex={0}
                  className="btn btn-outline w-full justify-between"
                >
                  {form.watch("clients").length > 0
                    ? `${form.watch("clients").length} client(s) selected`
                    : "Select clients"}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m6 9 6 6 6-6" />
                  </svg>
                </label>
                <ul
                  tabIndex={0}
                  className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-full max-h-60 overflow-y-auto"
                >
                  {clients.map((client) => (
                    <li key={client.id}>
                      <label className="label cursor-pointer justify-start">
                        <input
                          type="checkbox"
                          className="checkbox"
                          checked={form.watch("clients").includes(client.id)}
                          onChange={(e) => {
                            const updatedClients = e.target.checked
                              ? [...form.watch("clients"), client.id]
                              : form
                                  .watch("clients")
                                  .filter((id) => id !== client.id);
                            form.setValue("clients", updatedClients);
                          }}
                        />
                        <span className="label-text ml-2">{client.name}</span>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
              <label className="label">
                <span className="label-text-alt">
                  Select one or more clients to include in the report.
                </span>
              </label>
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">Projects</span>
              </label>
              <div className="dropdown">
                <label
                  tabIndex={0}
                  className="btn btn-outline w-full justify-between"
                >
                  {form.watch("projects").length > 0
                    ? `${form.watch("projects").length} project(s) selected`
                    : "Select projects"}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="m6 9 6 6 6-6" />
                  </svg>
                </label>
                <ul
                  tabIndex={0}
                  className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-full max-h-60 overflow-y-auto"
                >
                  {selectedClients.length > 0 ? (
                    selectedClients.map((clientId) => {
                      const client = clients.find((c) => c.id === clientId);
                      const clientProjects = projects.filter(
                        (p) => p.client_id === clientId
                      );
                      return (
                        <li key={clientId} className="menu-title">
                          <span>{client?.name}</span>
                          <ul>
                            {clientProjects.map((project) => (
                              <li key={project.id}>
                                <label className="label cursor-pointer justify-start">
                                  <input
                                    type="checkbox"
                                    className="checkbox"
                                    checked={form
                                      .watch("projects")
                                      .includes(project.id)}
                                    onChange={(e) => {
                                      const updatedProjects = e.target.checked
                                        ? [
                                            ...form.watch("projects"),
                                            project.id,
                                          ]
                                        : form
                                            .watch("projects")
                                            .filter((id) => id !== project.id);
                                      form.setValue(
                                        "projects",
                                        updatedProjects
                                      );
                                    }}
                                  />
                                  <span className="label-text ml-2">
                                    {project.name}
                                  </span>
                                </label>
                              </li>
                            ))}
                          </ul>
                        </li>
                      );
                    })
                  ) : (
                    <li className="menu-title">
                      <span>Select a client to view projects</span>
                    </li>
                  )}
                </ul>
              </div>
              <label className="label">
                <span className="label-text-alt">
                  Select one or more projects to include in the report.
                </span>
              </label>
            </div>

            <div className="flex flex-col sm:flex-row gap-4">
              <div className="form-control flex-1">
                <label className="label">
                  <span className="label-text">Start Date</span>
                </label>
                <input
                  type="date"
                  className="input input-bordered w-full"
                  {...form.register("startDate")}
                />
              </div>
              <div className="form-control flex-1">
                <label className="label">
                  <span className="label-text">End Date</span>
                </label>
                <input
                  type="date"
                  className="input input-bordered w-full"
                  {...form.register("endDate")}
                />
              </div>
            </div>

            <div className="form-control">
              <label className="label cursor-pointer justify-start">
                <input
                  type="checkbox"
                  className="checkbox"
                  {...form.register("includeTags")}
                />
                <span className="label-text ml-2">Include Tags</span>
              </label>
              <label className="label">
                <span className="label-text-alt">
                  Check this to include tags in the report.
                </span>
              </label>
            </div>

            <button
              type="submit"
              className="btn btn-primary w-full"
              disabled={isGenerating}
            >
              {isGenerating ? (
                <span className="loading loading-spinner"></span>
              ) : (
                "Generate XLSX Report"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export const Route = createFileRoute("/reports")({
  component: ReportsPage,
});
