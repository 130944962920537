import React, { useEffect, useState } from "react";
import { createClerkSupabaseClient } from "@/App";
import { useUser } from "@clerk/clerk-react";
import { createFileRoute } from "@tanstack/react-router";
import { Plus, Trash2, Edit } from "lucide-react";

export const Route = createFileRoute("/clients-projects")({
  component: ClientsProjectsPage,
});

interface Client {
  id: string;
  user_id: string;
  name: string;
  created_at: string;
}

interface Project {
  id: string;
  client_id: string;
  user_id: string;
  name: string;
  created_at: string;
}

interface FormData {
  name: string;
  clientId: string;
}

type ActiveTab = "clients" | "projects";

export function ClientsProjectsPage(): React.ReactElement {
  const { user } = useUser();
  const [clients, setClients] = useState<Client[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<ActiveTab>("clients");
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [editingItem, setEditingItem] = useState<Client | Project | null>(null);
  const [formData, setFormData] = useState<FormData>({
    name: "",
    clientId: "",
  });
  const client = createClerkSupabaseClient();

  useEffect(() => {
    if (!user) return;
    loadData();
  }, [user]);

  async function loadData(): Promise<void> {
    setLoading(true);
    const [clientsResponse, projectsResponse] = await Promise.all([
      client.from("clients").select("*").eq("user_id", user?.id),
      client.from("projects").select("*").eq("user_id", user?.id),
    ]);

    if (!clientsResponse.error && clientsResponse.data) {
      setClients(clientsResponse.data as Client[]);
    }
    if (!projectsResponse.error && projectsResponse.data) {
      setProjects(projectsResponse.data as Project[]);
    }
    setLoading(false);
  }

  async function handleSubmit(
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> {
    e.preventDefault();
    setLoading(true);
    if (activeTab === "clients") {
      const { data, error } = await client
        .from("clients")
        .upsert({
          id: (editingItem as Client | null)?.id,
          user_id: user?.id,
          name: formData.name,
        })
        .select();
      if (!error && data) {
        setClients((prevClients) => {
          const newClients = [...prevClients];
          const index = newClients.findIndex((c) => c.id === data[0].id);
          if (index !== -1) {
            newClients[index] = data[0] as Client;
          } else {
            newClients.push(data[0] as Client);
          }
          return newClients;
        });
      }
    } else {
      const { data, error } = await client
        .from("projects")
        .upsert({
          id: (editingItem as Project | null)?.id,
          user_id: user?.id,
          client_id: formData.clientId,
          name: formData.name,
        })
        .select();
      if (!error && data) {
        setProjects((prevProjects) => {
          const newProjects = [...prevProjects];
          const index = newProjects.findIndex((p) => p.id === data[0].id);
          if (index !== -1) {
            newProjects[index] = data[0] as Project;
          } else {
            newProjects.push(data[0] as Project);
          }
          return newProjects;
        });
      }
    }
    setLoading(false);
    setIsDialogOpen(false);
    resetForm();
  }

  async function handleDelete(id: string): Promise<void> {
    setLoading(true);
    const { error } = await client.from(activeTab).delete().match({ id });
    if (!error) {
      if (activeTab === "clients") {
        setClients(clients.filter((client) => client.id !== id));
      } else {
        setProjects(projects.filter((project) => project.id !== id));
      }
    }
    setLoading(false);
  }

  function resetForm(): void {
    setFormData({
      name: "",
      clientId: "",
    });
    setEditingItem(null);
  }

  function handleEdit(item: Client | Project): void {
    setEditingItem(item);
    setFormData({
      name: item.name,
      clientId: (item as Project).client_id || "",
    });
    setIsDialogOpen(true);
  }

  function renderTable(
    data: Client[] | Project[],
    headers: string[]
  ): React.ReactNode {
    if (loading) {
      return (
        <div className="flex justify-center items-center h-32">
          <span className="loading loading-spinner loading-lg"></span>
        </div>
      );
    }

    if (data.length === 0) {
      return (
        <p className="text-center text-base-content/70">No {activeTab} found</p>
      );
    }

    return (
      <div className="overflow-x-auto">
        <table className="table">
          <thead>
            <tr>
              {headers.map((header) => (
                <th key={header}>{header}</th>
              ))}
              <th className="text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                {activeTab === "projects" && (
                  <td>
                    {clients.find((c) => c.id === (item as Project).client_id)
                      ?.name || "N/A"}
                  </td>
                )}
                <td>{new Date(item.created_at).toLocaleDateString()}</td>
                <td className="text-right">
                  <button
                    className="btn btn-square btn-sm btn-ghost mr-2"
                    onClick={() => handleEdit(item)}
                  >
                    <Edit className="h-4 w-4" />
                  </button>
                  <button
                    className="btn btn-square btn-sm btn-error"
                    onClick={() => handleDelete(item.id)}
                  >
                    <Trash2 className="h-4 w-4" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6">
      <div className="card bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title text-2xl font-bold">Clients & Projects</h2>
          <p className="text-base-content/70">
            Manage your clients and projects
          </p>
          <div className="tabs tabs-boxed">
            <a
              className={`tab ${activeTab === "clients" ? "tab-active" : ""}`}
              onClick={() => setActiveTab("clients")}
            >
              Clients
            </a>
            <a
              className={`tab ${activeTab === "projects" ? "tab-active" : ""}`}
              onClick={() => setActiveTab("projects")}
            >
              Projects
            </a>
          </div>
          <div className="mt-4">
            {activeTab === "clients" &&
              renderTable(clients, ["Name", "Created At"])}
            {activeTab === "projects" &&
              renderTable(projects, ["Name", "Client", "Created At"])}
          </div>
        </div>
      </div>

      <button
        className="btn btn-primary mt-4"
        onClick={() => setIsDialogOpen(true)}
      >
        <Plus className="mr-2 h-4 w-4" /> Add{" "}
        {activeTab === "clients" ? "Client" : "Project"}
      </button>

      <dialog className={`modal ${isDialogOpen ? "modal-open" : ""}`}>
        <div className="modal-box">
          <h3 className="font-bold text-lg">
            {editingItem ? "Edit" : "Add"}{" "}
            {activeTab === "clients" ? "Client" : "Project"}
          </h3>
          <p className="py-4">
            {editingItem
              ? "Edit the details below"
              : `Enter the details for your new ${activeTab === "clients" ? "client" : "project"}`}
          </p>
          <form onSubmit={handleSubmit}>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Name</span>
              </label>
              <input
                type="text"
                placeholder="Enter name"
                className="input input-bordered w-full"
                value={formData.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({ ...formData, name: e.target.value })
                }
              />
            </div>

            {activeTab === "projects" && (
              <div className="form-control mt-4">
                <label className="label">
                  <span className="label-text">Client</span>
                </label>
                <select
                  className="select select-bordered w-full"
                  value={formData.clientId}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setFormData({ ...formData, clientId: e.target.value })
                  }
                >
                  <option value="">Select client</option>
                  {clients.map((client) => (
                    <option key={client.id} value={client.id}>
                      {client.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="modal-action">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {editingItem ? "Update" : "Add"}
              </button>
              <button
                type="button"
                className="btn"
                onClick={() => {
                  setIsDialogOpen(false);
                  resetForm();
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </dialog>
    </div>
  );
}
